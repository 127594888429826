import types from "./types";
import api from "@/services/admin/api";
import customerApi from "@/services/client/customerApi";
import to from "@/helpers/to";
export default {
  state: {
    documentsAuditList: [],
    agentPerformanceList: [],
    callLogList: [],
    customerStatusList: [],
    missedCallList: [],
    smsHistoryList: [],
    remindersList: [],
    csrRealtimeTasksList: [],
    csrTasksList: [],
    nsfList: [],
    enrollmentList: [],
    csrTaskPerformanceList: [],
    inboundStatisticsList: [],
    agentList: [],
    inboundCallList: [],
    leadAssignReport: null,
    listenWhisperList: [],
    pendingChangesList: [],
    incomingVmList: [],
    docusignEnvelopeHistoryList: [],
    negotiatorAssignList: [],
    enrollmentStatusChangeList: [],
    campaignPerformanceList: [],
    creditPullList: [],
    clearDraftList: [],
    firstClearDraftList: [],
    pendingDispositionList: [],
    activeEnrollmentsList: [],
    creditorList: [],
    liveCallRoleList: [],
    customerTagList: [],
    settlementsList: [],
    settlementCandidatesList: [],
    snfEnrollmentList: [],
    snfFirstDraftList: [],
    snfDncList: [],
    snfEgList: [],
    snfDaaEnrollmentList: [],
    newLineList: [],
    nexGenList: [],
    agentDropRateList: [],
    hotLeadAndXchangeList: [],
    droppedCustomersList: [],
    agentStatustList: null,
    agentPerformanceNewList: [],
    agentPhoneList: [],
    fortivaScrubList: [],
    americanExpressScrubList: [],
    masterScrubList: [],
    discoverScrubList: [],
    oneMainScrubList: [],
    csTaskSummaryList: [],
    userActivityList: [],
    dispositionSettingsList: [],
    distributionQueueList: [],
    callMetricsList: [],
    nsfCancellationList: [],
    respondersList: [],
    csrTaskContactScheduleList: [],
    nsfGlobalList: [],
    snfAttorneyActivityList: [],
    enrolledWithoutSmsOptInList: [],
    snfPendingCancellationList: [],
    paymentsAndFeesList: [],
    leadTracEnrollmentList: [],
    debtChangesPortEnrollmentList: [],
    settlementsFeeList: [],
    settlementRequestList: [],
    snfPayoutList: [],
    usBankElanScrubList: [],
    firstBankScrubList: [],
    capitalOneScrubList: [],
    voidedSettlementList: [],
    dpgPaymentandFeesList: [],
    scrubPraList: [],
    sofiScrubList: [],
    purchaseClientInformationList: [],
    purchaseCreditorInformationList: [],
    firstLeadAssignmentList: [],
    purchaseCreditorInsuranceList: [],
    grtPurchasableCustomersList: [],
  },
  actions: {
    GET_GRT_PURCHASABLE_CUSTOMERS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getGrtPurchasableCustomersReport(payload));
      if (result) {
        commit("SET_GRT_PURCHASABLE_CUSTOMERS_REPORT", result.Data);
      }
    },

    GET_PURCHASE_CREDITOR_INSURANCE_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getPurchaseCreditorInsuranceReport(payload));
      if (result) {
        commit("SET_PURCHASE_CREDITOR_INSURANCE_REPORT", result.Data);
      }
    },

    GET_FIRST_LEAD_ASSIGNMENT_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getFirstLeadAssignmentReport(payload));
      if (result) {
        commit("SET_FIRST_LEAD_ASSIGNMENT_REPORT", result.Data);
      }
    },

    GET_PURCHASE_CREDITOR_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getPurchaseCreditorReport(payload));
      if (result) {
        commit("SET_PURCHASE_CREDITOR_REPORT", result.Data);
      }
    },

    GET_PURCHASE_CLIENT_INFORMATION_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getPurchaseClientInformationReport(payload));
      if (result) {
        commit("SET_PURCHASE_CLIENT_INFORMATION_REPORT", result.Data);
      }
    },

    GET_SOFI_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_SOFI_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_SOFI_SCRUB_REPORT", payload);
    },

    GET_DPG_PAYMENTS_AND_FEES_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getDpgPaymentsAndFeesReport(payload));
      if (result) {
        commit("SET_DPG_PAYMENTS_AND_FEES_REPORT", result.Data);
      }
    },

    GET_VOIDED_SETTLEMENT_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getVoidedSettlementReport(payload));
      if (result) {
        commit("SET_VOIDED_SETTLEMENT_REPORT", result.Data);
      }
    },

    GET_SCRUB_LIST_REPORT_PRA: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_SCRUB_LIST_REPORT_PRA: async ({ commit, state }, payload) => {
      commit("SET_SCRUB_LIST_REPORT_PRA", payload);
    },

    GET_CAPITAL_ONE_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_CAPITAL_ONE_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_CAPITAL_ONE_SCRUB_REPORT", payload);
    },

    GET_FIRST_BANK_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_FIRST_BANK_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_FIRST_BANK_SCRUB_REPORT", payload);
    },

    GET_USBANK_ELAN_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_USBANK_ELAN_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_USBANK_ELAN_SCRUB_REPORT", payload);
    },

    GET_SNF_PAYOUT_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSnfPayoutReport(payload));
      if (result) {
        commit("SET_SNF_PAYOUT_REPORT", result.Data);
      }
    },

    GET_SETTLEMENTS_REQUEST_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSettlementRequestReport(payload));
      if (result) {
        commit("SET_SETTLEMENTS_REQUEST_REPORT", result.Data);
      }
    },

    GET_UNSETTLED_CREDITOR_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getUnsettledCreditorReport(payload));
      return [err, result];
    },

    GET_NEW_LEAD_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getNewLeadReport(payload));
      return [err, result];
    },

    GET_CONTRACT_STATUSES_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getContractStatusesReport(payload));
      return [err, result];
    },

    GET_ENROLLED_CREDITORS_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getEnrolledCreditorsReport(payload));
      return [err, result];
    },

    GET_SETTLEMENTS_FEE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSettlementsFeeReport(payload));
      if (result) {
        commit("SET_SETTLEMENTS_FEE_REPORT", result.Data);
      }
    },

    GET_DEBT_CHANGES_POST_ENROLLMENT_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getDebtChangesPostEnrollmentReport(payload));

      if (result) {
        commit("SET_DEBT_CHANGES_POST_ENROLLMENT_REPORT", result.Data);
      }
    },

    GET_LEAD_TRAC_ENROLLMENTS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getLeadTracEnrollmentsReport(payload));

      if (result) {
        commit("SET_LEAD_TRAC_ENROLLMENTS_REPORT", result.Data);
      }
    },

    GET_CONNECT_GLOBAL_ACCOUNT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getConnectGlobalAccount(payload));
      return [err, result];
    },

    NEW_LINE_SUBMIT_APPLICATION: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(customerApi.submitApplication(payload));
      return [err, result];
    },

    GET_DOCUMENT_AUDIT_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getDocumentAuditReport(payload));
      if (result) {
        commit("SET_DOCUMENT_AUDIT_REPORT", result.Data);
      }
    },

    GET_CSR_TASK_CONTACT_SCHEDULE_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskContactScheduleReport(payload));
      if (result) {
        commit("SET_CSR_TASK_CONTACT_SCHEDULE_REPORT", result.Data);
      }
    },

    GET_RESPONDERS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSnfRespondersReport(payload));
      if (result) {
        commit("SET_RESPONDERS_REPORT", result.Data);
      }
    },

    GET_CALL_METRICS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallMetricsReport(payload));
      if (result) {
        commit("SET_CALL_METRICS_REPORT", result.Data);
      }
    },

    GET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskDistributionQueueReport(payload));
      if (result) {
        commit("SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT", result.Data);
      }
    },

    GET_DISPOSITION_SETTINGS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(
        api.getCustomerDispositionSettingsReport(payload)
      );
      if (result) {
        commit("SET_DISPOSITION_SETTINGS_REPORT", result.Data);
      }
    },

    GET_USER_ACTIVITY_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getUserActivityReport(payload));
      if (result) {
        commit("SET_USER_ACTIVITY_REPORT", result.Data);
      }
    },

    GET_CSR_TASK_SUMMARY_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskSummaryReport(payload));
      if (result) {
        commit("SET_CSR_TASK_SUMMARY_REPORT", result.Data);
      }
    },

    GET_SETTLEMENT_ASSIST_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getSettlementAssistReport(payload));
      return [err, result];
    },
    // MODIFY_SETTLEMENT_ASSIST_REPORT: async ({ commit, state }, payload) => {
    //   commit("SET_SETTLEMENT_ASSIST_REPORT", payload);
    // },

    GET_FORTIVA_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_FORTIVA_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_FORTIVA_SCRUB_REPORT", payload);
    },

    GET_AMERICAN_EXPRESS_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_AMERICAN_EXPRESS_SCRUB_REPORT: async (
      { commit, state },
      payload
    ) => {
      commit("SET_AMERICAN_EXPRESS_SCRUB_REPORT", payload);
    },

    GET_MASTER_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_MASTER_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_MASTER_SCRUB_REPORT", payload);
    },

    GET_DISCOVER_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_DISCOVER_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_DISCOVER_SCRUB_REPORT", payload);
    },

    GET_ONE_MAIN_SCRUB_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getScrubReport(payload));
      return [err, result];
    },
    MODIFY_ONE_MAIN_SCRUB_REPORT: async ({ commit, state }, payload) => {
      commit("SET_ONE_MAIN_SCRUB_REPORT", payload);
    },

    GET_AGENT_PHONE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentPerformanceNewReport(payload));
      if (result) {
        commit("SET_AGENT_PHONE_REPORT", result.Data);
      }
    },

    GET_AGENT_PERFORMANCE_NEW_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentPerformanceNewReport(payload));
      if (result) {
        commit("SET_AGENT_PERFORMANCE_NEW_REPORT", result.Data);
      }
    },

    GET_AGENT_STATUS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentStatusReport(payload));
      if (result) {
        commit("SET_AGENT_STATUS_REPORT", result.Data);
      }
    },

    GET_CALL_LOG_DETAIL: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCallLogDetail(payload));
      return [err, result];
    },

    GET_DROPPED_CUSTOMERS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getDroppedCustomersReport(payload));
      if (result) {
        commit("SET_DROPPED_CUSTOMERS_REPORT", result.Data);
      }
    },

    GET_HOT_LEAD_AND_XCHANGE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getHotLeadAndXchangeReport(payload));
      if (result) {
        commit("SET_HOT_LEAD_AND_XCHANGE_REPORT", result.Data);
      }
    },

    GET_AGENT_DROP_RATE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentDropRateReport(payload));
      if (result) {
        commit("SET_AGENT_DROP_RATE_REPORT", result.Data);
      }
    },

    GET_NEW_LINE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNewLineReport(payload));
      if (result) {
        commit("SET_NEW_LINE_REPORT", result.Data);
      }
    },

    GET_SNF_DAA_ENROLLMENT_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSNFDAAEnrollmentReport(payload));
      if (result) {
        commit("GET_SNF_DAA_ENROLLMENT_REPORT", result.Data);
      }
    },

    GET_DB_SALES_COMPENSATION_REPORT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDbSalesCompensationReport(payload));
      return [err, result];
    },
    GET_SNF_EG_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getSnfEGReport(payload));
      if (result) {
        commit("SET_SNF_EG_REPORT", result.Data);
      }
    },
    GET_SETTLEMENTS_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getSettlementsReport(payload));
      if (result) {
        commit("SET_SETTLEMENTS_REPORT", result.Data);
      }
    },

    GET_CUSTOMER_TAG_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerTagReport(payload));
      if (result) {
        commit("SET_CUSTOMER_TAG_REPORT", result.Data);
      }
    },

    GET_CREDITOR_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCreditorReport(payload));
      return [err, result];
    },
    MODIFY_CREDITOR_REPORT: async ({ commit, state }, payload) => {
      commit("SET_CREDITOR_REPORT", payload);
    },

    GET_ACTIVE_ENROLLMENTS_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getActiveEnrollmentsReport(payload));
      return [err, result];
    },
    MODIFY_ACTIVE_ENROLLMENTS_REPORT: async ({ commit, state }, payload) => {
      commit("SET_ACTIVE_ENROLLMENTS_REPORT", payload);
    },

    GET_AGENT_PERFORMANCE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getAgentPerformanceReport(payload));
      if (result) {
        commit("SET_AGENT_PERFORMANCE_REPORT", result.Data);
      }
    },
    GET_CALL_LOG_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallLogReport(payload));
      if (result) {
        let updatedData = result.Data.map((item) => {
          let LogText =
            item.MissedCallReason === ""
              ? item.CallType
              : `${item.CallType} (${item.MissedCallReason})`;

          if (item.TriggerLogId !== null) {
            LogText += " (Auto Call Trigger)";
          }

          return { ...item, LogText };
        });

        commit("SET_CALL_LOG_REPORT", updatedData);
      }
    },
    GET_CUSTOMER_STATUS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerStatusReport(payload));
      if (result) {
        commit("SET_CUSTOMER_STATUS_REPORT", result.Data);
      }
    },
    GET_MISSED_CALL_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getMissedCallReport(payload));
      if (result) {
        commit("SET_MISSED_CALL_REPORT", result.Data);
      }
    },
    GET_SMS_HISTORY_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSmsHistoryReport(payload));
      if (result) {
        commit("SET_SMS_HISTORY_REPORT", result.Data);
      }
    },
    GET_REMINDERS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getRemindersReport(payload));
      if (result) {
        commit("SET_REMINDERS_REPORT", result.Data);
      }
    },
    GET_CSR_REALTIME_TASK_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrRealtimeTasksReport(payload));
      if (result) {
        commit("SET_CSR_REALTIME_TASK_REPORT", result.Data);
      }
    },
    GET_CSR_TASK_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskReport(payload));
      if (result) {
        commit("SET_CSR_TASK_REPORT", result.Data);
      }
    },
    GET_SNF_PENDING_CANCELLATION_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskReport(payload));
      if (result) {
        commit("SET_SNF_PENDING_CANCELLATION_REPORT", result.Data);
      }
    },
    GET_NSF_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNsfReport(payload));
      if (result) {
        commit("SET_NSF_REPORT", result.Data);
      }
    },
    GET_NSF_CANCELLATION_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNsfReport(payload));
      if (result) {
        commit("SET_NSF_CANCELLATION_REPORT", result.Data);
      }
    },
    GET_ENROLLMENT_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getEnrollmentReport(payload));
      if (result) {
        commit("SET_ENROLLMENT_REPORT", result.Data);
      }
    },
    GET_LEAD_ASSIGN_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getLeadAssignReport(payload));
      if (result) {
        commit("SET_LEAD_ASSIGN_REPORT", result.Data);
      }
    },
    GET_CSR_TASK_PERFORMANCE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCsrTaskPerformanceReport(payload));
      if (result) {
        commit("SET_CSR_TASK_PERFORMANCE_REPORT", result.Data);
      }
    },
    GET_INBOUND_STATISTICS_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getInboundStatisticsReport(payload));

      if (result) {
        commit("SET_INBOUND_STATISTICS_REPORT", result.Data);
      }
    },

    GET_LIVE_CALL_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getLiveCallReport(payload));
      if (result) {
        commit(types.SET_LIVE_CALL_REPORT, result.Data);
      }
    },

    GET_PENDING_CHANGES_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getPendingChangesReport(payload));

      if (result) {
        commit("SET_PENDING_CHANGES_REPORT", result.Data);
      }
    },
    GET_INCOMING_VM_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getIncomingVmReport(payload));

      if (result) {
        commit("SET_INCOMING_VM_REPORT", result.Data);
      }
    },
    GET_DOCUSIGN_ENVELOPE_HISTORY_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getDocusignEnvelopeHistoryReport(payload));

      if (result) {
        commit("SET_DOCUSIGN_ENVELOPE_HISTORY_REPORT", result.Data);
      }
    },

    GET_NEGOTIATOR_ASSIGN_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNegotiatorAssignReport(payload));

      if (result) {
        commit("SET_NEGOTIATOR_ASSIGN_REPORT", result.Data);
      }
    },

    GET_ENROLLMENT_STATUS_CHANGE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getEnrollmentStatusChangeReport(payload));

      if (result) {
        commit("SET_ENROLLMENT_STATUS_CHANGE_REPORT", result.Data);
      }
    },
    GET_CAMPAIGN_PERFORMANCE_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getCampaignPerformanceReport(payload));

      if (result) {
        commit("SET_CAMPAIGN_PERFORMANCE_REPORT", result.Data);
      }
    },

    GET_CREDIT_PULL_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCreditPullReport(payload));
      if (result) {
        commit("SET_CREDIT_PULL_REPORT", result.Data);
      }
    },

    GET_CLEAR_DRAFT_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getClearDraftReport(payload));
      if (result) {
        commit("SET_CLEAR_DRAFT_REPORT", result.Data);
      }
    },
    GET_FIRST_CLEAR_DRAFT_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getFirstClearDraftReport(payload));
      if (result) {
        commit("SET_FIRST_CLEAR_DRAFT_REPORT", result.Data);
      }
    },

    GET_PENDING_DISPOSITION_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getPendingDispositionReport(payload));
      if (result) {
        commit("SET_PENDING_DISPOSITION_REPORT", result.Data);
      }
    },

    GET_SETTLEMENT_CANDIDATES_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getSettlementCandidatesReport(payload));
      return [err, result];
    },
    MODIFY_SETTLEMENT_CANDIDATES_REPORT: async ({ commit, state }, payload) => {
      commit("SET_SETTLEMENT_CANDIDATES_REPORT", payload);
    },

    GET_SNF_ENROLLMENT_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getSNFEnrollmentReport(payload));
      if (result) {
        commit("SET_SNF_ENROLLMENT_REPORT", result.Data);
      }
    },

    GET_SNF_FIRST_DRAFT_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getSNFFirstDraftReport(payload));
      if (result) {
        commit("SET_SNF_FIRST_DRAFT_REPORT", result.Data);
      }
    },

    GET_SNF_DNC_REPORT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getSnfDncReport(payload));
      if (result) {
        commit("SET_SNF_DNC_REPORT", result.Data);
      }
    },

    GET_NEXT_GEN_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNextGenReport(payload));
      if (result) {
        commit("SET_NEXT_GEN_REPORT", result.Data);
      }
    },

    GET_NSF_GLOBAL_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getNsfGlobalReport(payload));
      if (result) {
        commit("SET_NSF_GLOBAL_REPORT", result.Data);
      }
    },

    GET_SNF_ATTORNEY_ACTIVITY_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getSnfAttorneyActivityReport(payload));
      if (result) {
        commit("SET_SNF_ATTORNEY_ACTIVITY_REPORT", result.Data);
      }
    },

    GET_ENROLLED_WITHOUT_SMS_OPTIN_REPORT: async (
      { commit, state },
      payload
    ) => {
      let err, result;

      [err, result] = await to(api.getEnrolledWithoutSmsOptInReport(payload));
      if (result) {
        commit("SET_ENROLLED_WITHOUT_SMS_OPTIN_REPORT", result.Data);
      }
    },

    GET_PAYMENTS_AND_FEES_REPORT: async ({ commit, state }, payload) => {
      let err, result;

      [err, result] = await to(api.getPaymentsAndFeesReport(payload));
      if (result) {
        commit("SET_PAYMENTS_AND_FEES_REPORT", result.Data);
      }
    },

    RESET_REPORTS: async ({ commit, state }, payload) => {
      commit("RESET_REPORTS_DATA");
    },
  },
  mutations: {
    SET_GRT_PURCHASABLE_CUSTOMERS_REPORT: (state, payload) => {
      state.grtPurchasableCustomersList = payload;
    },
    SET_PURCHASE_CREDITOR_INSURANCE_REPORT: (state, payload) => {
      state.purchaseCreditorInsuranceList = payload;
    },
    SET_FIRST_LEAD_ASSIGNMENT_REPORT: (state, payload) => {
      state.firstLeadAssignmentList = payload;
    },
    SET_PURCHASE_CREDITOR_REPORT: (state, payload) => {
      state.purchaseCreditorInformationList = payload;
    },
    SET_PURCHASE_CLIENT_INFORMATION_REPORT: (state, payload) => {
      state.purchaseClientInformationList = payload;
    },

    SET_SOFI_SCRUB_REPORT: (state, payload) => {
      state.sofiScrubList = payload;
    },
    SET_FIRST_BANK_SCRUB_REPORT: (state, payload) => {
      state.firstBankScrubList = payload;
    },
    SET_SNF_PAYOUT_REPORT: (state, payload) => {
      state.snfPayoutList = payload;
    },
    SET_SETTLEMENTS_REQUEST_REPORT: (state, payload) => {
      state.settlementRequestList = payload;
    },
    SET_SETTLEMENTS_FEE_REPORT: (state, payload) => {
      state.settlementsFeeList = payload;
    },
    SET_DEBT_CHANGES_POST_ENROLLMENT_REPORT: (state, payload) => {
      state.debtChangesPortEnrollmentList = payload;
    },
    SET_LEAD_TRAC_ENROLLMENTS_REPORT: (state, payload) => {
      state.leadTracEnrollmentList = payload;
    },

    SET_PAYMENTS_AND_FEES_REPORT: (state, payload) => {
      state.paymentsAndFeesList = payload;
    },
    SET_SNF_PENDING_CANCELLATION_REPORT: (state, payload) => {
      state.snfPendingCancellationList = payload;
    },
    SET_ENROLLED_WITHOUT_SMS_OPTIN_REPORT: (state, payload) => {
      state.enrolledWithoutSmsOptInList = payload;
    },
    SET_SNF_ATTORNEY_ACTIVITY_REPORT: (state, payload) => {
      state.snfAttorneyActivityList = payload;
    },
    SET_NSF_GLOBAL_REPORT: (state, payload) => {
      state.nsfGlobalList = payload;
    },
    SET_DOCUMENT_AUDIT_REPORT: (state, payload) => {
      state.documentsAuditList = payload;
    },
    SET_CSR_TASK_CONTACT_SCHEDULE_REPORT: (state, payload) => {
      state.csrTaskContactScheduleList = payload;
    },
    SET_RESPONDERS_REPORT: (state, payload) => {
      state.respondersList = payload;
    },
    SET_NSF_CANCELLATION_REPORT: (state, payload) => {
      state.nsfCancellationList = payload;
    },
    SET_CALL_METRICS_REPORT: (state, payload) => {
      state.callMetricsList = payload;
    },
    SET_CSR_TASK_DISTRIBUTION_QUEUE_REPORT: (state, payload) => {
      state.distributionQueueList = payload;
    },
    SET_DISPOSITION_SETTINGS_REPORT: (state, payload) => {
      state.dispositionSettingsList = payload;
    },
    SET_USER_ACTIVITY_REPORT: (state, payload) => {
      state.userActivityList = payload;
    },
    SET_CSR_TASK_SUMMARY_REPORT: (state, payload) => {
      state.csTaskSummaryList = payload;
    },
    SET_SETTLEMENT_ASSIST_REPORT: (state, payload) => {
      state.settlementAssistList = payload;
    },
    SET_AGENT_PHONE_REPORT: (state, payload) => {
      state.agentPhoneList = payload;
    },
    SET_AGENT_PERFORMANCE_NEW_REPORT: (state, payload) => {
      state.agentPerformanceNewList = payload;
    },
    SET_AGENT_STATUS_REPORT: (state, payload) => {
      state.agentStatustList = payload;
    },
    SET_DROPPED_CUSTOMERS_REPORT: (state, payload) => {
      state.droppedCustomersList = payload;
    },
    SET_HOT_LEAD_AND_XCHANGE_REPORT: (state, payload) => {
      state.hotLeadAndXchangeList = payload;
    },

    SET_AGENT_DROP_RATE_REPORT: (state, payload) => {
      state.agentDropRateList = payload;
    },
    SET_NEXT_GEN_REPORT: (state, payload) => {
      state.nexGenList = payload;
    },
    SET_NEW_LINE_REPORT: (state, payload) => {
      state.newLineList = payload;
    },
    SET_SNF_EG_REPORT: (state, payload) => {
      state.snfEgList = payload;
    },
    SET_SNF_DNC_REPORT: (state, payload) => {
      state.snfDncList = payload;
    },
    SET_SNF_FIRST_DRAFT_REPORT: (state, payload) => {
      state.snfFirstDraftList = payload;
    },
    SET_SNF_ENROLLMENT_REPORT: (state, payload) => {
      state.snfEnrollmentList = payload;
    },
    SET_SETTLEMENT_CANDIDATES_REPORT: (state, payload) => {
      state.settlementCandidatesList = payload;
    },
    SET_SETTLEMENTS_REPORT: (state, payload) => {
      state.settlementsList = payload;
    },

    SET_LIVE_CALL_ROLE_LIST: (state, payload) => {
      state.liveCallRoleList = payload;
    },

    SET_CREDITOR_REPORT: (state, payload) => {
      state.creditorList = payload;
    },

    SET_ACTIVE_ENROLLMENTS_REPORT: (state, payload) => {
      state.activeEnrollmentsList = payload;
    },

    SET_PENDING_DISPOSITION_REPORT: (state, payload) => {
      state.pendingDispositionList = payload;
    },
    SET_FIRST_CLEAR_DRAFT_REPORT: (state, payload) => {
      state.firstClearDraftList = payload;
    },
    SET_CLEAR_DRAFT_REPORT: (state, payload) => {
      state.clearDraftList = payload;
    },
    SET_CREDIT_PULL_REPORT: (state, payload) => {
      state.creditPullList = payload;
    },
    SET_AGENT_PERFORMANCE_REPORT: (state, payload) => {
      state.agentPerformanceList = payload;
    },
    SET_LIVE_CALL_REPORT: (state, payload) => {
      if (payload && payload.InboundCalls && payload.InboundCalls.length > 0) {
        state.inboundCallList = payload.InboundCalls;
      } else {
        state.inboundCallList = [];
      }

      if (payload && payload.Agents && payload.Agents.length > 0) {
        state.agentList = payload.Agents;
      } else {
        state.agentList = [];
      }
    },
    GET_SNF_DAA_ENROLLMENT_REPORT: (state, payload) => {
      state.snfDaaEnrollmentList = payload;
    },

    SET_INBOUND_STATISTICS_REPORT: (state, payload) => {
      state.inboundStatisticsList = payload;
    },
    SET_CSR_TASK_PERFORMANCE_REPORT: (state, payload) => {
      state.csrTaskPerformanceList = payload;
    },
    SET_CALL_LOG_REPORT: (state, payload) => {
      state.callLogList = payload;
    },
    SET_CUSTOMER_STATUS_REPORT: (state, payload) => {
      state.customerStatusList = payload;
    },
    SET_MISSED_CALL_REPORT: (state, payload) => {
      state.missedCallList = payload;
    },
    SET_SMS_HISTORY_REPORT: (state, payload) => {
      state.smsHistoryList = payload;
    },
    SET_REMINDERS_REPORT: (state, payload) => {
      state.remindersList = payload;
    },
    SET_CSR_REALTIME_TASK_REPORT: (state, payload) => {
      state.csrRealtimeTasksList = payload;
    },
    SET_CSR_TASK_REPORT: (state, payload) => {
      state.csrTasksList = payload;
    },
    SET_NSF_REPORT: (state, payload) => {
      state.nsfList = payload;
    },
    SET_ENROLLMENT_REPORT: (state, payload) => {
      state.enrollmentList = payload;
    },
    SET_LEAD_ASSIGN_REPORT: (state, payload) => {
      state.leadAssignReport = payload;
    },
    SET_PENDING_CHANGES_REPORT: (state, payload) => {
      state.pendingChangesList = payload;
    },
    SET_INCOMING_VM_REPORT: (state, payload) => {
      state.incomingVmList = payload;
    },
    SET_DOCUSIGN_ENVELOPE_HISTORY_REPORT: (state, payload) => {
      state.docusignEnvelopeHistoryList = payload;
    },
    SET_NEGOTIATOR_ASSIGN_REPORT: (state, payload) => {
      state.negotiatorAssignList = payload;
    },
    SET_ENROLLMENT_STATUS_CHANGE_REPORT: (state, payload) => {
      state.enrollmentStatusChangeList = payload;
    },
    SET_CAMPAIGN_PERFORMANCE_REPORT: (state, payload) => {
      state.campaignPerformanceList = payload;
    },
    SET_CUSTOMER_TAG_REPORT: (state, payload) => {
      state.customerTagList = payload;
    },
    SET_FORTIVA_SCRUB_REPORT: (state, payload) => {
      state.fortivaScrubList = payload;
    },
    SET_AMERICAN_EXPRESS_SCRUB_REPORT: (state, payload) => {
      state.americanExpressScrubList = payload;
    },
    SET_MASTER_SCRUB_REPORT: (state, payload) => {
      state.masterScrubList = payload;
    },
    SET_USBANK_ELAN_SCRUB_REPORT: (state, payload) => {
      state.usBankElanScrubList = payload;
    },
    SET_DISCOVER_SCRUB_REPORT: (state, payload) => {
      state.discoverScrubList = payload;
    },
    SET_ONE_MAIN_SCRUB_REPORT: (state, payload) => {
      state.oneMainScrubList = payload;
    },
    SET_CAPITAL_ONE_SCRUB_REPORT: (state, payload) => {
      state.capitalOneScrubList = payload;
    },
    SET_VOIDED_SETTLEMENT_REPORT: (state, payload) => {
      state.voidedSettlementList = payload;
    },
    SET_DPG_PAYMENTS_AND_FEES_REPORT: (state, payload) => {
      state.dpgPaymentandFeesList = payload;
    },
    SET_SCRUB_LIST_REPORT_PRA: (state, payload) => {
      state.scrubPraList = payload;
    },

    RESET_REPORTS_DATA: (state) => {
      state.grtPurchasableCustomersList = [];
      state.purchaseCreditorInsuranceList = [];
      state.firstLeadAssignmentList = [];
      state.purchaseClientInformationList = [];
      state.sofiScrubList = [];
      state.scrubPraList = [];
      state.dpgPaymentandFeesList = [];
      state.voidedSettlementList = [];
      state.capitalOneScrubList = [];
      state.firstBankScrubList = [];
      state.usBankElanScrubList = [];
      state.americanExpressScrubList = [];
      state.masterScrubList = [];
      state.discoverScrubList = [];
      state.oneMainScrubList = [];
      state.agentPerformanceList = [];
      state.callLogList = [];
      state.customerStatusList = [];
      state.missedCallList = [];
      state.smsHistoryList = [];
      state.remindersList = [];
      state.csrRealtimeTasksList = [];
      state.csrTasksList = [];
      state.nsfList = [];
      state.enrollmentList = [];
      state.csrTaskPerformanceList = [];
      state.inboundStatisticsList = [];
      state.agentList = [];
      state.inboundCallList = [];
      state.leadAssignReport = null;
      state.pendingChangesList = [];
      state.incomingVmList = [];
      state.docusignEnvelopeHistoryList = [];
      state.negotiatorAssignList = [];
      state.enrollmentStatusChangeList = [];
      state.campaignPerformanceList = [];
      state.creditPullList = [];
      state.clearDraftList = [];
      state.firstClearDraftList = [];
      state.pendingDispositionList = [];
      state.activeEnrollmentsList = [];
      state.creditorList = [];
      state.customerTagList = [];
      state.settlementsList = [];
      state.settlementCandidatesList = [];
      state.snfEnrollmentList = [];
      state.snfFirstDraftList = [];
      state.snfDncList = [];
      state.snfEgList = [];
      state.snfDaaEnrollmentList = [];
      state.newLineList = [];
      state.nexGenList = [];
      state.agentDropRateList = [];
      state.hotLeadAndXchangeList = [];
      state.droppedCustomersList = [];
      state.agentStatustList = null;
      state.agentPerformanceNewList = [];
      state.agentPhoneList = [];
      state.fortivaScrubList = [];
      state.csTaskSummaryList = [];
      state.userActivityList = [];
      state.dispositionSettingsList = [];
      state.distributionQueueList = [];
      state.callMetricsList = [];
      state.nsfCancellationList = [];
      state.respondersList = [];
      state.csrTaskContactScheduleList = [];
      state.documentsAuditList = [];
      state.nsfGlobalList = [];
      state.snfAttorneyActivityList = [];
      state.enrolledWithoutSmsOptInList = [];
      state.snfPendingCancellationList = [];
      state.paymentsAndFeesList = [];
      state.leadTracEnrollmentList = [];
      state.debtChangesPortEnrollmentList = [];
      state.settlementsFeeList = [];
      state.settlementRequestList = [];
      state.snfPayoutList = [];
      state.purchaseCreditorInformationList = [];
    },
  },
};
