<template>
  <div v-if="isDeclinePopupOpen" class="backgroundLock">
    <div
      style="position: relative; margin: 10% auto"
      class="offset-xl-4 col-xl-3 offset-lg-3 col-lg-6 col-md-12 col-sm-12 col-xs-12"
    >
      <DeclineReasonPopup
        :parentName="'paymentChange'"
        @closeDeclineReasonPopup="closeDeclineReasonPopup"
        @paymentChangeDecline="paymentChangeDecline"
      ></DeclineReasonPopup>
    </div>
  </div>
  <div class="datagrid-table gcsTabsTableScroll creditorTable">
    <o-table :data="paymentChangesList" detailed detail-key="Id">
      <o-table-column
        field="Customer.Name"
        label="Customer Name"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ props.row.Customer.Name }}
        </template>
      </o-table-column>
      <o-table-column
        field="Request.RequestedBy.Name"
        label="Requested By"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ props.row.Request.RequestedBy.Name }}
        </template>
      </o-table-column>

      <o-table-column
        field="Request.RequestDate"
        label="Request Date"
        sortable
        searchable
      >
        <template v-slot="props">
          {{ formatGridDate(props.row.Request.RequestDate) }}
        </template>
      </o-table-column>
      <o-table-column field="Notes" label="Notes" sortable searchable>
        <template v-slot="props">
          <div style="max-width: 400px">
            {{ makeEmptyIfNull(props.row.Notes) }}
          </div>
        </template>
      </o-table-column>
      <o-table-column field="Request.IsEmergency" label="Is Emergency" sortable>
        <template v-slot="props">
          <input
            type="checkbox"
            v-model="props.row.Request.IsEmergency"
            class="checkbox-input generalCheckBox"
            disabled="true"
            style="border-radius: 4px; border: solid 1px #d1d1d1"
          />
        </template>
      </o-table-column>
      <o-table-column field="" label="Negative Cashflow" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              v-if="props.row.IsNegativeCashFlow"
              class="btn btn-danger btn-xs"
              @click="
                showCashFlow(props.row.Customer.Id, props.row.Customer.OfficeId)
              "
            >
              <i class="ri-error-warning-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>
      <o-table-column field="ViewClients" label="View Clients" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-primary btn-xs"
              @click="
                openClient(props.row.Customer.Id, props.row.Customer.Name)
              "
            >
              <i class="ri-search-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>
      <o-table-column field="ViewNotes" label="View Notes" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-xs"
              style="background-color: #f7b84b"
              @click="openNotes(props.row.Customer.Id)"
            >
              <i class="ri-file-edit-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="Approve" label="Approve" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-info btn-xs"
              @click="handleApprove(props.row.Id)"
              :disabled="isApproveLoading"
            >
              <i v-if="isApproveLoading == false" class="ri-check-line"></i>
              <i
                v-if="isApproveLoading"
                class="spinner-border spinner-border-sm text-light"
                style="margin-bottom: 3px; margin-left: 1px"
              ></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <o-table-column field="Decline" label="Decline" width="10">
        <template v-slot="props">
          <div class="text-center">
            <button
              class="btn btn-danger btn-xs"
              @click="handleDecline(props.row.Id)"
              :disabled="isLoading"
            >
              <i class="ri-close-line"></i>
            </button>
          </div>
        </template>
      </o-table-column>

      <template v-slot:detail="props">
        <perfect-scrollbar
          style="
            max-height: 350px;
            width: 800px;
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <table
            v-if="props.row.ChangeDetails && props.row.ChangeDetails.length > 0"
            class="details-table"
          >
            <h5 class="generalLabel pl-2" style="color: #3bafda">
              Change Details
            </h5>
            <tr>
              <td align="center"><strong>Status</strong></td>
              <td align="center"><strong>Effective Date</strong></td>
              <td align="center"><strong>New Date</strong></td>
              <td align="center"><strong>Payee</strong></td>
              <td align="center"><strong>Account Number</strong></td>
              <td align="center"><strong>Payment Amount</strong></td>
              <td align="center"><strong>Fee Amount</strong></td>
              <td align="center"><strong>Payment Type</strong></td>
            </tr>

            <tr v-for="(item, index) in props.row.ChangeDetails" :key="index">
              <td align="center">
                {{ getChangeStatus(item) }}
              </td>
              <td align="center">
                {{ formatShortDate(item.EffectiveDate) }}
              </td>
              <td align="center">
                {{ formatShortDate(item.NewDate) }}
              </td>
              <td align="center">
                {{ item.PayeeName }}
              </td>
              <td align="center">
                {{ item.PayeeAccountNumber }}
              </td>
              <td align="center">
                {{ formatMoney(item.PaymentAmount) }}
              </td>
              <td align="center">
                {{ formatMoney(item.FeeAmount) }}
              </td>
              <td align="center">
                {{ item.PaymentType }}
              </td>
            </tr>
          </table>
        </perfect-scrollbar>
        <section
          v-if="
            props.row.ChangeDetails == null ||
            props.row.ChangeDetails.length == 0
          "
        >
          <div style="display: flex; align-items: center">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line mr-1"
              ></i>
            </p>
            <p class="noMargin">Nothing Change Details.</p>
          </div>
        </section>

        <perfect-scrollbar
          style="
            max-height: 350px;
            width: 521px;
            overflow-y: auto;
            overflow-x: hidden;
          "
        >
          <table
            v-if="
              props.row.ApplicableFees && props.row.ApplicableFees.length > 0
            "
            class="details-table mt-2"
          >
            <h5 class="generalLabel pl-2" style="color: #3bafda">
              Applicable Fees
            </h5>
            <tr>
              <td align="center"><strong>Fee Amount</strong></td>
              <td align="center"><strong>Global Fee Type</strong></td>
              <td align="center"><strong>Fee Date</strong></td>
            </tr>

            <tr v-for="(item, index) in props.row.ApplicableFees" :key="index">
              <td align="center">
                {{ formatMoney(item.FeeAmount) }}
              </td>
              <td align="center">
                {{ item.GlobalFeeType }}
              </td>
              <td align="center">
                {{ formatShortDate(item.FeeDate) }}
              </td>
            </tr>
          </table>
        </perfect-scrollbar>

        <section
          v-if="
            props.row.ApplicableFees == null ||
            props.row.ApplicableFees.length == 0
          "
        >
          <div style="display: flex; align-items: center">
            <p class="noMargin">
              <i
                style="font-size: 24px; color: #939393"
                class="ri-question-line mr-1"
              ></i>
            </p>
            <p class="noMargin">No Applicable Fees.</p>
          </div>
        </section>
      </template>
    </o-table>
  </div>
</template>

<script>
import types from "./types";
import { mapState } from "vuex";
import formatMixin from "@/mixins/formatMixin";
import utilitiesMixin from "@/mixins/utilitiesMixin";
import DeclineReasonPopup from "./DeclineReasonPopup";
import deepCopy from "@/helpers/deepCopy";
import _orderby from "lodash";
export default {
  name: "PaymentChanges",
  components: { DeclineReasonPopup },
  mixins: [formatMixin, utilitiesMixin],
  props: {
    paymentChangesList: {
      Type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedId: false,
      isDeclinePopupOpen: false,
      isLoading: false,
      isApproveLoading: false,
    };
  },
  computed: mapState({}),
  async mounted() {},
  methods: {
    showCashFlow(customerId, officeId) {
      if (customerId > 0 && officeId > 0) {
        this.$emit("showCashFlow", {
          CustomerId: customerId,
          OfficeId: officeId,
        });
      }
    },
    getChangeStatus(item) {
      let rtn = "Updating Payment";
      if (item.PaymentId == null || item.PaymentId == 0) {
        rtn = "New Payment";
      } else if (item.PaymentAmount == 0 && item.FeeAmount == 0) {
        rtn = "Deactivating Payment";
      }

      return rtn;
    },
    makeEmptyIfNull(val) {
      let rtn = "";
      if (val) {
        rtn = val;
      }
      return rtn;
    },
    refreshData() {
      this.$emit("refreshData");
    },
    openClient(customerId, customerName) {
      this.$Emitter.emit("viewCustomer", {
        customerId: customerId,
        customerName: customerName,
      });
    },
    openNotes(customerId) {
      this.$Emitter.emit("viewNotePanel", { CustomerId: customerId });
    },
    async handleApprove(dataId) {
      this.isApproveLoading = true;
      let err, result;
      let dataToSend = {
        Id: dataId,
      };

      [err, result] = await this.$store.dispatch(
        types.PC_APPROVE_PAYMENTCHANGES,
        dataToSend
      );

      if (result) {
        this.refreshData();
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isApproveLoading = false;
    },
    async paymentChangeDecline(reason, isRevert) {
      this.isLoading = true;
      let err, result;
      let dataToSend = {
        Id: this.selectedId,
        DeclineReason: reason,
      };

      [err, result] = await this.$store.dispatch(
        types.PC_DECLINE_PAYMENTCHANGES,
        dataToSend
      );

      if (result) {
        this.refreshData();
        this.$swal("Success!", result.Message, "success");
      } else {
        let errMsg = this.getApiErrorMessage(err);
        this.$swal("Error!", errMsg, "error");
      }
      this.isLoading = false;
    },

    async handleDecline(dataId) {
      this.selectedId = dataId;
      this.isDeclinePopupOpen = true;
    },
    closeDeclineReasonPopup() {
      this.isDeclinePopupOpen = false;
    },
  },
};
</script>

<style lang="scss">
.detail td:hover {
  background-color: #fafafa !important;
}
.details-table tr:hover {
  background-color: #fafafa !important;
}
.detail {
  background: #fafafa !important;
}
</style>
