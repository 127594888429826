import axios from "axios";

const API_URL =
  process.env.VUE_APP_IS_DEVELOPMENT == "true"
    ? process.env.VUE_APP_API_DEV
    : process.env.VUE_APP_API;

const instance = axios.create({
  baseURL: "",
  timeout: 120000,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    const token = JSON.parse(sessionStorage.userInfo).token;

    if (token !== null && token !== undefined) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  function (err) {
    return Promise.reject(err);
  }
);
export default {

  getMfaLogs: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "getequifaxmfalogs",
      Data: data,
    });
  },
  getDitLogs: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "getequifaxauthlogs",
      Data: data,
    });
  },
  sendDitRequest: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "equifaxdit",
      Data: data,
    });
  },
  sendDitKbaAnswers: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "equifaxditkba",
      Data: data,
    });
  },
  sendDitMfaRequest: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "equifaxmfa",
      Data: data,
    });
  },
  sendDitMfaValidationRequest: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "equifaxmfavalidate",
      Data: data,
    });
  },
  getMailContentById: (data) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "getMailContentById",
      Data: data,
    });
  },

  getOneTimeSettlementFee: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "getOneTimeSettlementFee",
      Data: data,
    });
  },

  getGlobalFeeTypeByGroupingKey: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "GetGlobalFeeTypeByOfficeAndStateId",
      Data: data,
    });
  },
  getCustomerLanguageId: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getcustomerlanguage",
      Data: data,
    });
  },
  getPortalAutoLoginLink: (data) => {
    return instance.post(`${API_URL}/api/UserMessage`, {
      Type: "GetPortalLink",
      Data: data,
    });
  },
  getSettlementFeeInfo: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getSettlementFeeInfo",
      Data: data,
    });
  },
  getRestrictedCreditorStatuses: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "Getcreditorstatusrestriction",
    });
  },
  getAllOptionalProductTypes: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getalloptionalproducttypes",
      Data: data,
    });
  },

  getBalanceSummary: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.cashflowsummary",
      Data: data,
    });
  },
  getAllChargesTypes: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallchargestypes",
      Data: data,
    });
  },
  checkConsecutiveDrafts: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "checkConsecutiveDrafts",
      Data: data,
    });
  },
  getChargesInfo: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.charges",
      Data: data,
    });
  },
  getCustomerOptinStatus: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "getsmsoptinstatus",
      Data: data,
    });
  },
  getUserActivityHistory: (data) => {
    return instance.post(`${API_URL}/api/UserActivity`, {
      Type: "getHistory",
    });
  },
  getProcessedFees: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "settlement.processedfees",
      Data: data,
    });
  },
  getBlockedCreditorsAndCreditTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getBlockedCreditorInfo",
    });
  },
  getAuditorUsers: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getauditor",
    });
  },
  getQuickEmailNames: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getquickemail",
      Data: data,
    });
  },
  getContactTriggersByTaskId: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskContactTrigger`, {
      Type: "getcontacttriggerbytask",
      Data: data,
    });
  },

  OptOutAutoCall: (data) => {
    return instance.post(`${API_URL}/api/CsrTaskContactTrigger`, {
      Type: "updateautocalloptinstatus",
      Data: data,
    });
  },
  postGlobalMeridians: (data) => {
    return instance.post(`${API_URL}/api/meridian`, {
      Type: "leadPosting",
      Data: data,
    });
  },

  getXChangeCustomers: (data) => {
    return instance.post(`${API_URL}/api/XChange`, {
      Type: "grid.get",
      Data: data,
    });
  },
  updateGlobalInfoarmationDraftAmount: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "updatedraftamount",
      Data: data,
    });
  },
  getDraftAmount: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getdraftamount",
      Data: data,
    });
  },
  assignXChangeCustomer: (data) => {
    return instance.post(`${API_URL}/api/XChange`, {
      Type: "grid.assign",
      Data: data,
    });
  },
  approvePaymentChanges: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "payment.approve",
      Data: data,
    });
  },
  declinePaymentChanges: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "payment.decline",
      Data: data,
    });
  },

  paymentChangesCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "payment.create",
      Data: data,
    });
  },
  getPayeeDetails: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "payment.getpayee",
      Data: data,
    });
  },
  getSettledCreditors: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcustomercreditor",
      Data: data,
    });
  },
  requestAgentIp: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getrequestip",
    });
  },
  getCustomerPrimaryPhone: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getPhoneByCustomerId",
      Data: data,
    });
  },
  getCustomerSettlementDetails: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getSettlementDetailsByCreditor",
      Data: data,
    });
  },
  getCreditorInfo: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getCreditorInfo",
      Data: data,
    });
  },
  getCsrTicketDashboard: (payload) => {
    return instance.post(`${API_URL}/api/TicketDashboard`, payload);
  },
  updateBulkRole: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "bulkroleupdate",
      Data: data,
    });
  },

  updateBulkUser: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "bulkassignuserupdate",
      Data: data,
    });
  },

  updateBulkDate: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "bulkduedateupdate",
      Data: data,
    });
  },
  getUserAccessRoles: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getuseraccessroles",
    });
  },
  getAllUsersAccessTickets: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallusersaccesstickets",
    });
  },
  updateNegotiatorTaskNote(data) {
    return instance.post(`${API_URL}/api/NegotiatorTask/`, {
      Type: "updateNegotiatorTaskNote",
      Data: data,
    });
  },
  getAllCustomerTagTypes: () => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getAllCustomerTagType",
    });
  },
  getCustomer: (customerId) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "getbyid",
      Data: { Id: customerId },
    });
  },
  getPhoneTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, { Type: "getphonetypes" });
  },
  getPriorityTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getprioritytypes",
    });
  },
  getHardships: () => {
    return instance.post(`${API_URL}/api/Reference`, { Type: "gethardships" });
  },
  getOccupations: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getoccupations",
    });
  },

  getCreditRating: () => {
    return instance.get(`${API_URL}/api/CreditRating`);
  },
  getMaritalStatus: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getmaritalstatustypes",
    });
  },
  getRelationShip: () => {
    return instance.get(`${API_URL}/api/RelationShip`);
  },
  getOccupation: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getoccupations",
    });
  },

  deleteEmail: (payload) => {
    return instance.post(`${API_URL}/api/Customer`, payload);
  },
  deletePhone: (payload) => {
    return instance.post(`${API_URL}/api/Customer`, payload);
  },

  getDispositions: (customerId) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "getdispositionlogsbycustomerid",
      Data: { CustomerId: customerId },
    });
  },
  insertDispositionV2: (data) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "insertV2",
      Data: data,
    });
  },
  getCustomerLogs: (customerId) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "getsystemlogsbycustomerid",
      Data: { CustomerId: customerId },
    });
  },

  getCampaignTypes: () => {
    return instance.get(`${API_URL}/api/CampaignType`);
  },
  getAssignedAgents: (agentId) => {
    return instance.post(`${API_URL}/api/User`, {
      Type: "assignedAgents",
      Data: { Id: agentId },
    });
  },
  getNotifications: () => {
    return instance.post(`${API_URL}/api/Notification`, { Type: "pending" });
  },
  checkNewNotifications: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "getnew",
      Data: data,
    });
  },
  dismissNotification: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "dismiss",
      Id: data.Id,
    });
  },
  postponeNotification: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "postpone",
      Id: data.Id,
      Minutes: data.Minutes,
    });
  },
  getNotificationsByDate: (year, month) => {
    return instance.post(`${API_URL}/api/Notification/Year`, {
      Type: "pendingbymonthandyear",
      Month: month,
      Year: year,
    });
  },
  getCustomerRemindes: (customerId) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "customerall",
      CustomerId: customerId,
    });
  },
  insertCustomer: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "insert",
      Data: data,
    });
  },
  updateCustomer: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "update",
      Data: data,
    });
  },
  updateCustomerWithoutTags: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "updateWithoutTags",
      Data: data,
    });
  },

  getCustomers: (data) => {
    return instance.post(`${API_URL}/api/CustomerSearch`, data);
  },
  insertNotification: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "insert",
      Notification: data,
    });
  },
  updateNotification: (data) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "update",
      Notification: data,
    });
  },
  deleteNotification: (id) => {
    return instance.post(`${API_URL}/api/Notification/`, {
      Type: "delete",
      Id: id,
    });
  },
  getZipData: (data) => {
    return instance.post(`${API_URL}/api/ziplookup`, data);
  },
  deleteCustomer: (customerId) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "delete",
      Data: { Id: customerId },
    });
  },
  getLast5Reminders: (customerId) => {
    return instance.post(`${API_URL}/api/Notification`, {
      Type: "customerlast5",
      CustomerId: customerId,
    });
  },
  getLast5Dispos: (customerId) => {
    return instance.post(`${API_URL}/api/CustomerDispositionLog`, {
      Type: "getlast5dispositionlogsbycustomerid",
      Data: { CustomerId: customerId },
    });
  },
  getFavs: () => {
    return instance.get(`${API_URL}/api/UserFavorite`);
  },
  insertFav: (data) => {
    return instance.post(`${API_URL}/api/UserFavorite`, data);
  },
  deleteFav: (data) => {
    return instance.delete(`${API_URL}/api/UserFavorite`, data);
  },

  sendOptinRequest: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "sendoptinrequestsms",
      Data: data,
    });
  },

  getSmsLogs: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "smslogs",
      Data: { PhoneNumber: data },
    });
  },

  optOutNumber: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "optout",
      Data: { PhoneNumber: data },
    });
  },

  sendSms: (data) => {
    return instance.post(`${API_URL}/api/Sms`, {
      Type: "sendsms",
      Data: data,
    });
  },

  getCallLogs: (data) => {
    return instance.post(`${API_URL}/api/CallLog/ByPhoneNumbers`, data);
  },

  getCampaignsWithDisabled: (CampaignId) => {
    return instance.post(`${API_URL}/api/Campaign`, {
      Type: "getforcustomerpage",
      Data: { Id: CampaignId },
    });
  },

  getAccountTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getaccounttypes",
    });
  },
  getResidenceOwnershipTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getresidenceownershiptypes",
    });
  },
  getExpenseTypes: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getexpensetypes",
    });
  },
  getCompanyLogo: (data) => {
    return instance.get(`${API_URL}/api/DomainLogo`);
  },
  getCustomerDocumentTemplates: () => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "listtemplates",
    });
  },
  getCustomerDocumentList: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "list",
      Data: data,
    });
  },
  deleteCustomerDocument: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "delete",
      Data: data,
    });
  },
  renameCustomerDocument: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "rename",
      Data: data,
    });
  },
  uploadExtraDocuments: (data) => {
    return instance.post(`${API_URL}/api/CustomerDocument`, {
      Type: "upload",
      Data: data,
    });
  },

  getNotes: (data) => {
    return instance.post(`${API_URL}/api/CustomerNotes`, {
      Type: "getnotes",
      CustomerId: data.Id,
      CsrTaskId: data.CsrTaskId,
      CreditorId: data.CreditorId,
    });
  },
  addNotes: (data) => {
    return instance.post(`${API_URL}/api/CustomerNotes`, {
      Type: "addnote",
      CustomerId: data.CustomerId,
      Note: data.Note,
      CsrTaskId: data.CsrTaskId,
      CreditorId: data.CreditorId,
    });
  },
  getSalesAgents: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getsalesagents",
    });
  },
  getCsrs: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcsrs",
    });
  },
  getNegotiators: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getnegotiators",
    });
  },
  getGlobalDocTypes: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.documents.gettypes",
      Data: data,
    });
  },
  uploadDocumentToGlobal: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.documents.upload",
      Data: data,
    });
  },
  getCustomerCreditors: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getall",
      Data: data,
    });
  },
  updateCustomerCreditor: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "update",
      Data: data,
    });
  },
  getCustomerCreditor: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getcreditor",
      Data: data,
    });
  },
  addCustomerCreditor: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "addnew",
      Data: data,
    });
  },
  deleteCustomerCreditor: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "delete",
      Data: data,
    });
  },
  settleCreditor: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "submitNegotiator",
      Data: data,
    });
  },
  voidSettlement: (data) => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "voidCreditorSettlement",
      Data: data,
    });
  },
  getNegotiatorPhases: () => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getNegotiatorPhases",
    });
  },

  getGcsTabInfo: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.info",
      Data: data,
    });
  },
  getGcsTabStatement: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.statement",
      Data: data,
    });
  },
  getGcsTabCashflow: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.cashflow",
      Data: data,
    });
  },

  getNewGcsTabCashflow: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.newcashflow",
      Data: data,
    });
  },

  getGcsTabNotes: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.notes",
      Data: data,
    });
  },
  getGcsTabPayments: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.payments",
      Data: data,
    });
  },
  getGcsTabDocuments: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.documents",
      Data: data,
    });
  },
  getGcsTabDeposits: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.deposits",
      Data: data,
    });
  },
  getGcsTabFees: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.fees",
      Data: data,
    });
  },
  getGcsTabTransactions: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.transactions",
      Data: data,
    });
  },
  getGcsTabDrafts: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.drafts",
      Data: data,
    });
  },

  getCreditorStatus: () => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getCreditorStatus",
    });
  },
  getSettlementStatus: () => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getSettlementStatus",
    });
  },

  getCreditorAccountHolders: () => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getAccountHolders",
    });
  },

  getCreditorAccountTypes: () => {
    return instance.post(`${API_URL}/api/Creditor`, {
      Type: "getAccountTypes",
    });
  },
  getCreditorSettingsByCreditorName: (data) => {
    return instance.post(`${API_URL}/api/CreditorAverageSettlementPercentage`, {
      Type: "GetByName",
      Data: data,
    });
  },
  verifyUsps: (data) => {
    return instance.post(`${API_URL}/api/UspsVerification`, {
      Type: "verify",
      Address: data.Address,
      Address2: data.Address2,
      City: data.City,
      StateShort: data.StateShort,
      Zip: data.Zip,
    });
  },

  unassignTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "unassign",
      Data: data,
    });
  },

  getAllTasks: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "getallv2", //getall
      Data: data,
    });
  },

  getTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "gettask",
      Data: data,
    });
  },

  assignTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "assign",
      Data: data,
    });
  },

  submitTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "submit",
      Data: data,
    });
  },

  addNewTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "addnew",
      Data: data,
    });
  },

  gcsTabSkipPaymentApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "skippayment.approve",
      Data: data,
    });
  },
  gcsTabClientInfoApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientinfo.approve",
      Data: data,
    });
  },

  gcsTabSkipPaymentCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "skippayment.create",
      Data: data,
    });
  },
  gcsTabDocReviewApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "docreview.approve",
      Data: data,
    });
  },
  gcsTabAmendmentApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "amendment.approve",
      Data: data,
    });
  },
  gcsTabClientStatusApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientstatus.approve",
      Data: data,
    });
  },
  gcsTabAdditionalNoteApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "additionalnote.approve",
      Data: data,
    });
  },
  gcsTabDraftCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "draft.create",
      Data: data,
    });
  },
  feeChangesCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "fee.create",
      Data: data,
    });
  },
  chargeChangesCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "charge.create",
      Data: data,
    });
  },
  gcsTabDraftApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "draft.approve",
      Data: data,
    });
  },
  gcsTabExtraPaymentApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "extrapayment.approve",
      Data: data,
    });
  },
  getAllDraftChanges: () => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "getall",
    });
  },
  gcsTabExtraPaymentDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "extrapayment.decline",
      Data: data,
    });
  },

  gcsTabDraftDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "draft.decline",
      Data: data,
    });
  },

  gcsTabAdditionalNoteDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "additionalnote.decline",
      Data: data,
    });
  },
  gcsTabAmendmentDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "amendment.decline",
      Data: data,
    });
  },
  gcsTabClientInfoDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientinfo.decline",
      Data: data,
    });
  },
  gcsTabClientStatusDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientstatus.decline",
      Data: data,
    });
  },
  gcsTabDocReviewDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "docreview.decline",
      Data: data,
    });
  },
  gcsTabSkipPaymentDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "skippayment.decline",
      Data: data,
    });
  },
  gcsTabClinetInfoCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientinfo.create",
      Data: data,
    });
  },
  gcsTabClientInfoChanceType: () => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientinfo.changetypes",
    });
  },
  gcsTabDocReviewCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "docreview.create",
      Data: data,
    });
  },
  gcsTabAmendmentCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "amendment.create",
      Data: data,
    });
  },
  gcsTabClientStatusCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientstatus.create",
      Data: data,
    });
  },
  gcsTabClientStatusReleaseTypes: () => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "clientstatus.releasetypes",
    });
  },
  gcsTabAdditionalNoteCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "additionalnote.create",
      Data: data,
    });
  },
  gcsTabExtraPaymentCreate: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "extrapayment.create",
      Data: data,
    });
  },
  gcsTabFeesGetTypes: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "fees.gettypes",
      Data: data,
    });
  },
  gcsTabExtraPaymentDraftTypes: () => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "extrapayment.drafttypes",
    });
  },

  getDocusignPrevalidate: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.prevalidate",
      Data: data,
    });
  },

  getDocusignPendingEnvelope: (data) => {
    return instance.post(`${API_URL}/api/Docusign`, {
      Type: "docusign.envelopehistory",
      Data: data,
    });
  },
  getDocusignEnvelopeStatus: (data) => {
    return instance.post(`${API_URL}/api/Docusign`, {
      Type: "docusign.checkenvelopestatus",
      Data: data,
    });
  },
  sendDocusignReminder: (data) => {
    return instance.post(`${API_URL}/api/Docusign`, {
      Type: "docusign.sendenvelopereminder",
      Data: data,
    });
  },

  voidDocusignEnvelope: (data) => {
    return instance.post(`${API_URL}/api/Docusign`, {
      Type: "docusign.voidenvelope",
      Data: data,
    });
  },
  sendDocusignEnvelope: (data) => {
    return instance.post(`${API_URL}/api/Docusign`, {
      Type: "docusign.sendenvelope",
      Data: data,
    });
  },
  getAvailableTemplateByCustomer: (data) => {
    return instance.post(`${API_URL}/api/Docusign`, {
      Type: "docusign.templatetypesbycustomer",
      Data: data,
    });
  },
  getDocusignContractValidation: (data) => {
    return instance.post(`${API_URL}/api/Docusign`, {
      Type: "docusign.getcontractvalidation",
      Data: data,
    });
  },

  paymetCalculator: (data) => {
    return instance.post(`${API_URL}/api/PaymentCalculator`, {
      Type: "simple",
      Data: data,
    });
  },

  attorneyInfo: (data) => {
    return instance.post(`${API_URL}/api/Attorney`, {
      Type: "getinfo",
      Data: data,
    });
  },

  phonePayInfo: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.tab.info.phonepayinfo",
      Data: data,
    });
  },

  accountNumberValidation: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "accountnumbervalidation",
      Data: data,
    });
  },

  getNegotiatorTaskAttoneryInfo: (data) => {
    return instance.post(`${API_URL}/api/Attorney`, {
      Type: "getinfo",
      Data: data,
    });
  },
  getNegotiatorTasksPaymentTypes: () => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "getPaymentTypes",
    });
  },
  getNegotiatorCsrCorrespondence: () => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "getNegotiatorCsrCorrespondenceTypes",
    });
  },
  getAllNegotiatorTasks: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "getall",
      Data: data,
    });
  },
  getNegotiatorTaskDetails: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "getTaskDetails",
      Data: data,
    });
  },
  saveNegotiatorTask: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "saveTask",
      Data: data,
    });
  },
  postponeNegotiatorTask: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "postpone",
      Data: data,
    });
  },
  removeNegotiatorTask: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "removeTask",
      Data: data,
    });
  },
  requestReview: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "requestReview",
      Data: data,
    });
  },
  approveReview: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "approveReview",
      Data: data,
    });
  },
  attorneyTaskDecline: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "attorneyTaskDecline",
      Data: data,
    });
  },

  getCampaigns: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcampaignnames",
    });
  },
  getCampaignsV2: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getcampaignnamesV2",
      Data: data,
    });
  },

  insertCustomerNewAccount: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.newaccount",
      Data: data,
    });
  },
  insertCustomerProgramDrafts: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.programdrafts",
      Data: data,
    });
  },
  insertCustomerNewAccountAndProgramDrafts: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.newaccountandprogramdrafts",
      Data: data,
    });
  },
  negotiatonPhaseGcsValidate: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "settlement.validatefunds",
      Data: data,
    });
  },

  createAuthorizationText: (data) => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "createAuthorizationText",
      Data: data,
    });
  },
  getAuthorizationType: (data) => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "getAuthorizationType",
      Data: data,
    });
  },
  getAllAuthorizationType: () => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "getAll",
    });
  },
  replaceRepeatableText: (data) => {
    return instance.post(`${API_URL}/api/DocumentAuthorizationType`, {
      Type: "replaceRepeatableText",
      Data: data,
    });
  },

  updateBulkStatus: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "bulkstatusupdate",
      Data: data,
    });
  },
  updateBulkAgent: (data) => {
    return instance.post(`${API_URL}/api/Customer`, {
      Type: "bulkagentupdate",
      Data: data,
    });
  },
  distributeCsrTask: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "distribute",
      Data: data,
    });
  },
  checkCanRequestTask: (id) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "checktasktyperole",
      Data: { UserId: id },
    });
  },

  addSettlement: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "settlement.add",
      Data: data,
    });
  },

  editPayeeAddress: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.address.edit",
      Data: data,
    });
  },
  removePayeeAddress: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.address.remove",
      Data: data,
    });
  },
  addPayeeAddress: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.address.add",
      Data: data,
    });
  },
  editPayeeContact: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.contact.edit",
      Data: data,
    });
  },
  removePayeeContact: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.contact.remove",
      Data: data,
    });
  },
  addPayeeContact: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.contact.add",
      Data: data,
    });
  },
  editPayeeBank: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.bank.edit",
      Data: data,
    });
  },
  removePayeeBank: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.bank.remove",
      Data: data,
    });
  },
  addPayeeBank: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.bank.add",
      Data: data,
    });
  },
  getPayee: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.get",
      Data: data,
    });
  },
  addPayee: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.add",
      Data: data,
    });
  },
  searchPayee: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "payee.search",
      Data: data,
    });
  },

  getAllPendingChanges: () => {
    return instance.post(`${API_URL}/api/DraftChanges/`, {
      Type: "getall",
    });
  },

  getCustomerMessages: (data) => {
    return instance.post(`${API_URL}/api/UserMessage/`, {
      Type: "getcustomermessages",
      Data: data,
    });
  },
  getSendMessage: (data) => {
    return instance.post(`${API_URL}/api/UserMessage/`, {
      Type: "sendmessage",
      Data: data,
    });
  },

  getCustomerInfoForPortal: (data) => {
    return instance.post(`${API_URL}/api/UserMessage/`, {
      Type: "getcustomerdata",
      Data: data,
    });
  },
  getPolicyGroupsByCustomer: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getpolicygroups",
      Data: data,
    });
  },
  csrTaskUpdateNote: (data) => {
    return instance.post(`${API_URL}/api/CsrTask`, {
      Type: "updatenote",
      Data: data,
    });
  },

  sendArrayAuthAnswers: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "answerauthentication",
      Data: data,
    });
  },
  requestCreditPullReport: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "requestreport",
      Data: data,
    });
  },

  getCreditPullHistory: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "getreporthistory",
      Data: data,
    });
  },

  downloadCreditPullHistory: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "downloadreport",
      Data: data,
    });
  },

  sendHotLeadLog: (data) => {
    return instance.post(`${API_URL}/api/ServiceBusLog`, {
      Type: "insert",
      Data: data,
    });
  },

  getAllPaymentOptions: () => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "getallpaymentoptions",
    });
  },

  questionPulled: (data) => {
    return instance.post(`${API_URL}/api/CreditPull`, {
      Type: "questionpulled",
      Data: data,
    });
  },

  pendingChangesFeeApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "fee.approve",
      Data: data,
    });
  },
  pendingChangesFeeDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "fee.decline",
      Data: data,
    });
  },

  pendingChangesChargeApprove: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "charge.approve",
      Data: data,
    });
  },
  pendingChangesChargeDecline: (data) => {
    return instance.post(`${API_URL}/api/DraftChanges`, {
      Type: "charge.decline",
      Data: data,
    });
  },

  getActivePoaCreditor: (data) => {
    return instance.post(`${API_URL}/api/CreditorContact`, {
      Type: "getactivepoacreditor",
      Data: data,
    });
  },

  sendEFax: (data) => {
    return instance.post(`${API_URL}/api/PoaInfo`, {
      Type: "SendEfax",
      Data: data,
    });
  },

  getCustomerCancellationInfo: (data) => {
    return instance.post(`${API_URL}/api/Global`, {
      Type: "customer.cancellationinfo",
      Data: data,
    });
  },

  insertUserActivity: (data) => {
    return instance.post(`${API_URL}/api/UserActivity`, {
      Type: "insert",
      Data: data,
    });
  },
  bulkAssignNegotiatorTask: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "bulkNegotiatorAssign",
      Data: data,
    });
  },
  getPaymentTypesByPolicyGroup: (data) => {
    return instance.post(`${API_URL}/api/NegotiatorTask`, {
      Type: "getPaymentTypesByPgid",
      Data: data,
    });
  },

  updateCsrTriggerLogAnsweredUser: (data) => {
    return instance.post(`${API_URL}/api/PhoneConnect`, {
      Type: "UpdateCsrTriggerLogAnsweredUser",
      Data: data,
    });
  },

  createOrUpdateLeadTrac: (data) => {
    return instance.post(`${API_URL}/api/LeadTrac`, {
      Type: "CreateOrUpdate",
      Data: data,
    });
  },

  pushToTCI: (data) => {
    return instance.post(`${API_URL}/api/DecisionLenderApi`, data);
  },

  submitApplication: (data) => {
    return instance.post(`${API_URL}/api/NewLineLoan`, {
      Type: "SubmitApplication",
      Data: data,
    });
  },

  getApplication: (data) => {
    return instance.post(`${API_URL}/api/NewLineLoan`, {
      Type: "GetApplication",
      Data: data,
    });
  },
  getPaymentCalculatorStateValidation: (data) => {
    return instance.post(`${API_URL}/api/Reference`, {
      Type: "PaymentCalculatorStateValidation",
      Data: data,
    });
  },
};
