import types from "./types";
import api from "@/services/client/customerApi";
import adminApi from "@/services/admin/api";
import to from "@/helpers/to";
import downloadApi from "@/services/fileServices/downloadService";

export default {
  state: {
    clientCreditors: [],
    customerDocuments: [],
    globalDocumentTypeList: [],
    docusignValidationSettings: [],
  },
  actions: {
    GET_EQUIFAX_API_SETTINGS: async ({ state }) => {
      let err, result;
      [err, result] = await to(adminApi.getEquifaxApiSettings());
      return [err, result];
    },
    GET_EQUIFAX_MFA_LOGS: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getMfaLogs(payload));
      return [err, result];
    },
    GET_EQUIFAX_DIT_LOGS: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getDitLogs(payload));
      return [err, result];
    },
    SEND_EQUIFAX_DIT_REQUEST: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.sendDitRequest(payload));
      return [err, result];
    },
    SEND_EQUIFAX_KBA_ANSWERS: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.sendDitKbaAnswers(payload));
      return [err, result];
    },
    SEND_EQUIFAX_MFA_REQUEST: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.sendDitMfaRequest(payload));
      return [err, result];
    },
    SEND_EQUIFAX_MFA_VALIDATION_REQUEST: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.sendDitMfaValidationRequest(payload));
      return [err, result];
    },
    GET_MAIL_CONTENT_BY_ID: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getMailContentById(payload));
      return [err, result];
    },
    GET_GLOBAL_FEE_TYPES_BY_GROUPING_KEYS: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGlobalFeeTypeByGroupingKey(payload));
      return [err, result];
    },
    GET_PAYMENT_CALCULATOR_STATE_VALIDATION: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getPaymentCalculatorStateValidation(payload));
      return [err, result];
    },

    GET_ALL_OPTIONAL_PRODUCT_TYPES: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getAllOptionalProductTypes(payload));
      return [err, result];
    },

    GET_BALANCE_SUMMARY: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getBalanceSummary(payload));
      return [err, result];
    },
    GET_CUSTOMER_OPTIN_STATUS: async ({ state }, payload) => {
      let err, result;
      [err, result] = await to(api.getCustomerOptinStatus(payload));
      return [err, result];
    },
    PUSH_TO_TCI: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.pushToTCI(payload));
      return [err, result];
    },

    POST_LEAD_TRAC: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.createOrUpdateLeadTrac(payload));
      return [err, result];
    },

    POST_GLOBAL_MERIDIANS: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.postGlobalMeridians(payload));
      return [err, result];
    },

    UPDATE_DRAFT_AMOUNT: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(
        api.updateGlobalInfoarmationDraftAmount(payload)
      );
      return [err, result];
    },

    GET_EDIT_CLIENT_INFO: async ({ commit, state }, payload) => {
      let err, result;
      [err, result] = await to(api.getGcsTabInfo(payload));
      return [err, result];
    },

    GET_CLIENT: async ({ commit }, CustomerId) => {
      let result;
      result = await to(api.getCustomer(CustomerId));

      return result;
    },

    GET_CLIENTLOGS: async ({ commit }, customerId) => {
      let err, result;
      [err, result] = await to(api.getCustomerLogs(customerId));

      if (result) {
        commit(types.SET_CLIENTLOGS, result.Data);
      }
    },

    DELETEEMAIL: async ({ commit }, payload) => {
      let data;
      if (payload.CoCustomerId > 0 && payload.CustomerId > 0) {
        data = {
          Type: "deleteCoCustomerEmail",
          Data: {
            EmailId: payload.Id,
            CoCustomerId: payload.CoCustomerId,
          },
        };
      } else if (payload.CustomerId > 0) {
        data = {
          Type: "deleteEmail",
          Data: {
            EmailId: payload.Id,
            CustomerId: payload.CustomerId,
          },
        };
      }

      let result = await to(api.deleteEmail(data));
      return result;
    },
    DELETEPHONE: async ({ commit }, payload) => {
      let data;

      if (payload.CoCustomerId > 0 && payload.CustomerId > 0) {
        data = {
          Type: "deleteCoCustomerPhone",
          Data: {
            PhoneId: payload.Id,
            CoCustomerId: payload.CoCustomerId,
          },
        };
      } else if (payload.CustomerId > 0) {
        data = {
          Type: "deletePhone",
          Data: {
            PhoneId: payload.Id,
            CustomerId: payload.CustomerId,
          },
        };
      }
      let result = await to(api.deletePhone(data));
      return result;
    },

    GETASSIGNEDAGENTS: async ({ commit, state }, agentId) => {
      let err, result;

      [err, result] = await to(api.getAssignedAgents(agentId));
      if (result) {
        return result;
      }
    },
    UPDATE_CLIENT: async ({ commit }, payload) => {
      let result = await to(api.updateCustomer(payload));
      return result;
    },
    UPDATE_CLIENT_WITHOUT_TAGS: async ({ commit }, payload) => {
      let result = await to(api.updateCustomerWithoutTags(payload));
      return result;
    },
    INSERT_NEW_CLIENT: async ({ commit }, payload) => {
      return await to(api.insertCustomer(payload));
    },
    GET_CALL_LOGS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCallLogs(payload));

      if (result) {
        return result;
      }
    },
    GET_CAMPAIGNS: async ({ commit }, Id) => {
      let err, result;

      [err, result] = await to(api.getCampaignsWithDisabled(Id));

      if (result) {
        return result;
      }
    },
    GET_PAYMENT_CALC_VALUE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.paymetCalculator(payload));

      return [err, result];
    },

    GET_ATTORNEY_INFO: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.attorneyInfo(payload));

      return [err, result];
    },

    RESET_STATES: async ({ commit }) => {
      commit(types.SET_RESET_STATES);
    },
    GET_CUSTOMERDOCUMENTS: async ({ commit, state }) => {
      if (state.customerDocuments.length > 0) return;

      let err, result;

      [err, result] = await to(api.getCustomerDocumentTemplates());
      if (result) {
        commit("SET_CUSTOMERDOCUMENTS", result.Data);
      }
    },
    GENERATE_CUSTOMER_TEMPLATE: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(downloadApi.generateCustomerTemplate(payload));
      return [err, result];
    },
    GET_CUSTOMERDOCUMENTLIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerDocumentList(payload));

      return [err, result];
    },
    REFRESH_CUSTOMERDOCUMENTLIST: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCustomerDocumentList(payload));
      if (result) {
        commit("SET_CUSTOMERDOCUMENTLIST", result.Data);
      }
    },

    DOWNLOAD_CUSTOMER_DOCUMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(downloadApi.downloadCustomerDocument(payload));
      return [err, result];
    },
    DELETE_CUSTOMER_DOCUMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.deleteCustomerDocument(payload));
      return [err, result];
    },
    RENAME_CUSTOMER_DOCUMENT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.renameCustomerDocument(payload));
      return [err, result];
    },
    UPLOAD_EXTRA_DOCUMENT: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.uploadExtraDocuments(payload));
      return [err, result];
    },
    GET_GLOBALDOCUMENTTYPES: async ({ commit, state }, payload) => {
      if (state.globalDocumentTypeList.length > 0) return;

      let err, result;

      [err, result] = await to(api.getGlobalDocTypes(payload));
      if (result) {
        commit("SET_GLOBALDOCUMENTTYPES", result.Data);
      }
    },
    UPLOAD_DOCUMENTTOGLOBAL: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.uploadDocumentToGlobal(payload));
      return [err, result];
    },
    VALIDATE_ACCOUNT_NUMBER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.accountNumberValidation(payload));
      return [err, result];
    },
    INSERT_GLOBALNEWACCOUNT: async ({ commit }, payload) => {
      let result = await to(api.insertCustomerNewAccount(payload));
      return result;
    },
    INSERT_PROGRAMDRAFTS: async ({ commit }, payload) => {
      let result = await to(api.insertCustomerProgramDrafts(payload));
      return result;
    },
    INSERT_GLOBALNEWACCOUNTAndPROGRAMDRAFTS: async ({ commit }, payload) => {
      let result = await to(
        api.insertCustomerNewAccountAndProgramDrafts(payload)
      );
      return result;
    },
    //DOCUSIGN

    GET_DOCUSIGN_CONTRACT_VALIDATION: async ({ commit, state }, payload) => {
      let err, result;
      if (state.docusignValidationSettings.length == 0) {
        [err, result] = await to(api.getDocusignContractValidation(payload));

        if (result && result.Data && result.Data.length > 0) {
          let tmp = result.Data.replaceAll(/=/g, ":");
          eval("tmp = " + tmp);
          commit(
            types.SET_DOCUSIGN_CONTRACT_VALIDATION,
            tmp.ValidationJsonData
          );
        }
      }
    },

    GET_DOCUSIGN_PREVALIDATE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDocusignPrevalidate(payload));
      return [err, result];
    },
    GET_DOCUSIGN_HISTORY: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDocusignPendingEnvelope(payload));
      return result;
    },
    GET_DOCUSIGN_ENVELOPE_STATUS: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getDocusignEnvelopeStatus(payload));
      return [err, result];
    },
    SEND_DOCUSIGN_REMINDER: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.sendDocusignReminder(payload));
      return [err, result];
    },
    VOID_DOCUSIGN_ENVELOPE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.voidDocusignEnvelope(payload));
      return [err, result];
    },
    SEND_DOCUSIGN_ENVELOPE: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.sendDocusignEnvelope(payload));
      return [err, result];
    },
    GET_AVAILABLE_TEMPLATE_BY_CUSTOMER: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getAvailableTemplateByCustomer(payload));

      return result;
    },
    CREATE_CLIENT_INFO_CHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabClinetInfoCreate(payload));
      return [err, result];
    },
    CREATE_DOCUMENT_REVIEW_CHANGES: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.gcsTabDocReviewCreate(payload));
      return [err, result];
    },
    GET_POLICY_GROUPS_BY_CUSTOMER: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getPolicyGroupsByCustomer(payload));
      return [err, result];
    },

    GET_CREDIT_PULL_HISTORY: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.getCreditPullHistory(payload));
      return [err, result];
    },

    SEND_ARRAY_AUTH_ANSWERS: async ({ commit }, payload) => {
      let err, result;

      [err, result] = await to(api.sendArrayAuthAnswers(payload));
      return [err, result];
    },

    REQUEST_CREDIT_PULL_REPORT: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.requestCreditPullReport(payload));
      return [err, result];
    },

    DOWNLOAD_CREDIT_PULL_HISTORY: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.downloadCreditPullHistory(payload));
      return [err, result];
    },

    SET_AUTH_QUESTION_PULLED: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.questionPulled(payload));
      return [err, result];
    },

    SUBMIT_APPLICATION: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.submitApplication(payload));
      return [err, result];
    },

    GET_APPLICATION: async ({ commit }, payload) => {
      let err, result;
      [err, result] = await to(api.getApplication(payload));
      return [err, result];
    },
  },
  mutations: {
    SET_DOCUSIGN_CONTRACT_VALIDATION: (state, payload) => {
      state.docusignValidationSettings = payload;
    },

    SETFAV: (state, payload) => {
      state.favorites = payload;
    },
    SET_CLIENT_CREDITORS: (state, payload) => {
      state.clientCreditors = payload;
    },
    SET_RESET_STATES: (state) => {
      state.clientCreditors = [];
      state.customerDocuments = [];
      state.docusignValidationSettings = [];
    },
    SET_CUSTOMERDOCUMENTS: (state, payload) => {
      state.customerDocuments = payload;
    },

    SET_GLOBALDOCUMENTTYPES: (state, payload) => {
      state.globalDocumentTypeList = payload;
    },
  },
};
